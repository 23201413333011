<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-6">
                <h3>
                  Invoice
                </h3>
                <router-link :to="{name:'dashboard'}">
                  Dashboard
                </router-link>
                \
                Invoice
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Invoice</h4>
              </div>
              <!--                        <div class="col-4">-->
              <!--                            <h4>Manage Institution</h4>-->
              <!--                        </div>-->
              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">
              </div>
              <div class="row">
                <!--                <div class="col-sm-3 col-md-3 col-lg-3">-->
                <!--                  <v-select v-model="filter.other" outlined dense :items="payment_methods" item-value="value"-->
                <!--                            item-text="text"></v-select>-->
                <!--                </div>-->

                <!--                <div class="col-sm-2 col-md-2 col-lg-2">-->
                <!--                  <v-btn depressed class="btn btn-primary text-white" @click="searchInvoice">Search</v-btn>-->
                <!--                  <v-btn depressed class="btn btn-secondary" @click="resetForm">Reset</v-btn>-->
                <!--                </div>-->

                <div class="col-12">
                  <v-card>
                    <v-card-text>
                      <v-tabs vertical>
                        <v-tab>
                          Invoice
                        </v-tab>
                        <v-tab>
                          Upcoming payments
                        </v-tab>
                        <v-tab>
                          Pending payment
                        </v-tab>

                        <v-tab-item>
                          <div class="row">
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <invoice></invoice>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-tab-item>
                        <v-tab-item>
                          <div class="row">
                            <div class="col-12">
                              <div class="card">
                                <div class="card-body">
                                  <upcoming-payments></upcoming-payments>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-tab-item>
                        <v-tab-item>
                          <div class="row">
                            <div class="col-12">
                              <pending-payments></pending-payments>
                            </div>
                          </div>
                        </v-tab-item>

                      </v-tabs>
                    </v-card-text>
                  </v-card>

                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>


import Invoice from "./Invoice.vue";
import PendingPayments from "./PendingPayments.vue";
import UpcomingPayments from "./UpcomingPayments.vue";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";

const feeService = new StudentFeeService();

export default {
  name: "billing",
  components: {
    Invoice,
    PendingPayments,
    UpcomingPayments
  },
  data() {
    return {
      isBusy: false,
      sortBy: "invoiceNumber",
      sortDesc: false,


      upcoming_payments: [],
      pending_payments: [],
      payment_status: [
        {value: null, text: "Payment Status"},
        {value: "pending", text: "Pending"},
        {value: "completed", text: "Completed"},
        {value: "cancelled", text: "Cancelled"}
      ],
      payment_methods: [
        {value: null, text: "Select Payment Method"},
        {value: "paypal", text: "Paypal"},
        {value: "khalti", text: "Khalti"},
        {value: "stripe", text: "Stripe"},
        {value: "esewa", text: "esewa"}
      ],
      filter: {},
      fields: [
        {key: "invoice_total", label: "#", sortable: true},
        {key: "orderDate", sortable: true},
        {key: "Payment_method", label: "Payment Method", sortable: true},
        {key: "status", sortable: true},
        {key: "invoiceTotal", label: "Invoice Total", sortable: false},
        {key: "action", sortable: false}
      ],
      data: [],
      pageOptions: [5, 10, 15]
    };
  },
  mounted() {
    let query = this.$route.query;
    if (query.oid && query.amt && query.refId) {
      this.processEsewaPayment();
    }
  },
  methods: {
    processEsewaPayment() {
      feeService.validateEsewa(this.$route.query).then(response => {
        this.$router.push({name: 'invoice'});
      })
    }, processConnectIPS() {
      feeService.validateConnectIPS(this.$route.query).then(response => {
        this.$router.push({name: 'invoice'});
      })
    }
  },
};
</script>
