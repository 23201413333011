<template>
  <div class="card">
    <div class="card-header" v-if="selected && selected.length>0">
      <div class="card-title pt-3 px-3 d-flex justify-content-between">
        <div class="breadcrumb-left">
        </div>
        <div class="breadcrumb-right">
          <v-btn
                 @click="payWith()"
                 class="mt-4 btn btn-primary  mr-1"
                 dark>
            <i class="fa fa-plus"></i>
            Proceed to payment
          </v-btn>

        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <th>
            <v-checkbox v-if="pending_payments && pending_payments.length>0"
                        @change="selectAll()"
                        v-model="all"
            ></v-checkbox>
          </th>
          <th>Date</th>
          <th>Title</th>
          <th>Total</th>
          </thead>
          <tbody v-if="pending_payments && pending_payments.length>0">
          <tr v-for="(payment, index) of pending_payments" :key="index">
            <td>
              <v-checkbox
                  v-model="selected"
                  :value="payment.id"
              ></v-checkbox>
            </td>
            <td>{{ payment.due_date_text }}</td>
            <td>{{ payment.title }}</td>
            <td>{{ `Rs. ${payment.amount}` }}</td>
          </tr>
          </tbody>
          <tbody v-else>
          <tr>
            <td colspan="50" class="text-center">No pending payments found</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
<!--      <div class="col-12 text-right" >
        <v-btn depressed class="btn btn-primary text-white" @click="payWith">Proceed To Payment</v-btn>
      </div>-->

      <payment v-if="selected && selected.length>0" ref="payment" :dialog="dialog" :selected="selected" :gateways="gateways" @close_payment_dialog="getPendingPayments"></payment>
    </div>
  </div>

</template>


<script>
import InvoiceService from "@/core/services/invoice/InvoiceService";
import Payment from "@/view/pages/invoice/Payment";
import PaymentGatewaySettingService from "@/core/services/site-setting/PaymentGatewaySettingService";

const gatewaySettingService = new PaymentGatewaySettingService();
const inovice = new InvoiceService();
export default {
  name: "pending-payments",
  components: {
    Payment
  },
  data() {
    return {
      pending_payments: [],
      selected: [],
      gateways: {},
      all: false,
      dialog: false,
    }
  },
  mounted() {
    this.getPendingPayments();
  },
  methods: {
    getPendingPayments() {
      inovice.getPendingPayments().then(response => {
        this.pending_payments = response.data.payment;
      }).catch(error => {
        this.$snotify.error("Something went wrong");
      }).finally(() => {
        this.dialog = false
      })
    },
    selectAll() {
      if (this.all) {
        this.pending_payments.map(ele => {
          this.selected.push(ele.id)
        })
      } else {
        this.selected = [];
      }
    },
    payWith() {
      this.dialog = true
      this.getAvailablePaymentGateways();
    },
    getAvailablePaymentGateways() {
      gatewaySettingService
          .getAvailablePaymentGateways()
          .then(response => {
              this.gateways = response.data.gateways
          })
    },
  }
}
</script>

<style scoped>

</style>