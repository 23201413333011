<template>
  <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="px-3">
          <th>#</th>
          <th>Date</th>
          <th>Total</th>
          <th class="pr-3 text-center">Options</th>
        </tr>
        </thead>
        <tbody v-if="inovices  && inovices.length>0">
        <tr v-for="(invoice, index) of inovices" :key="index">
          <td class="px-3">{{ invoice.invoice_no }}</td>
          <td class="px-3">{{ invoice.created_at }}</td>
          <td class="px-3">{{ invoice.invoice_total }}</td>
          <td>
            <template>
              <b-dropdown
                  size="sm"
                  variant="link"
                  toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                  no-caret
                  right
                  no-flip
              >
                <template v-slot:button-content>
                  <i class="ki ki-bold-more-hor"></i>
                </template>
                <div class="navi navi-hover min-w-md-250px">

                  <b-dropdown-text tag="div" class="navi-item">
                    <a @click.prevent="openInvoice(invoice)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-eye"></i>
                                </span>
                      <span class="navi-text">View invoice</span>

                    </a>
                  </b-dropdown-text>

                </div>
              </b-dropdown>
            </template>

          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="4" class="text-center">
            <strong>No invoice found</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <b-pagination   v-if="inovices.length > 0"
          class="pull-right mt-7"
          @input="getInvoice"
          v-model="page"
          :total-rows="total"
          :per-page="perPage"
          first-number
          last-number
      ></b-pagination>
  </div>
</template>

<script>
import InvoiceService from "@/core/services/invoice/InvoiceService";

const inovice = new InvoiceService();
export default {
  name: "invoice",
  data() {
    return {
      page: null,
      total: null,
      perPage: null,
      inovices: []
    }
  },
  mounted() {
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      inovice.getInvoice().then(response => {
        this.inovices = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    openInvoice(invoice) {
      let routeData = this.$router.resolve({name: 'invoice-detail', params: {id: invoice.id}});
      window.open(routeData.href, '_blank');
    }


  },
}
</script>

<style scoped>

</style>