<template>
  <v-dialog v-model="dialog" max-width="800" persistent>
    <v-card>
      <v-toolbar
          dark>
        <v-card-title>

          <span>  Payment</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="cancelPayment">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <div class="col-12 ">
              <v-radio-group class="text-center"
                             v-model="payment_method"
                             row>
                <v-radio v-show="checkGatewayEnable(method.value)" v-for="(method, index) in payment_methods" :key="index"
                         :value="method.value"
                >
                  <template v-slot:label>
                    <img v-if="method.value=='esewa'" src="/media/payment-options/esewa-logo.png" height="50px">
                    <img v-if="method.value=='khalti'" src="/media/payment-options/khalti.png" alt="" height="50px">
                    <img v-if="method.value=='connect-ips'" src="/media/payment-options/connect-ips.png" alt=""
                         height="50px">
                  </template>


                </v-radio>
              </v-radio-group>
            </div>
            <div class="col-12  text-center ">
              <span class="font-weight-bold font-size-lg text-danger"
                    v-if="$v.payment_method.$error">Please select payment method</span>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn  class="btn btn-standard cancel-btn"
                depressed @click="cancelPayment">Cancel</v-btn>
        <v-btn   class="text-white ml-2 btn btn-primary"
                 depressed @click="processPayment">Pay now</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import StudentFeeService from "@/core/services/user/student/fee/StudentFeeService";

const feeService = new StudentFeeService();

export default {
  name: "Payment.vue",
  props: ['dialog', 'selected', 'gateways'],
  validations: {
    payment_method: {required}
  },
  data() {
    return {
      payment_method: null,
      payment_methods: [
        {title: 'eSewa', value: 'esewa'},
        {title: 'Khalti', value: 'khalti'},
        {title: 'Connect IPS', value: 'connect-ips'},
      ]
    }
  },
  computed: {
    user() {
      return this.$store.getters.currentUser
    }
  },
  methods: {
    cancelPayment() {
      this.cancelProcessedPayment()
    },
    checkGatewayEnable(type) {

      switch (type) {
        case 'esewa':
          return this.gateways.is_esewa_enabled ? true : false
        case 'khalti':
          return this.gateways.is_khalti_enabled ? true : false
        case 'connect-ips':
          return this.gateways.is_connect_ips_enabled ? true : false
      }
    },

    processPayment() {
      this.$v.$touch();
      if (this.$v.payment_method.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000)
      } else {
        feeService.processPayment({payment: this.selected}).then(response => {
          if (!response.data)
            this.cancelProcessedPayment();
          else {
            switch (this.payment_method) {
              case "esewa":
                this.esewaPayment(response.data.payment);
                break;
              case "khalti":
                this.payWithKhalti(response.data.payment);
                break;
              case "connect-ips":
                this.initiateConnectIPS(response.data.payment);
                break;
            }
          }
        })
      }
    }, cancelProcessedPayment() {
      feeService.cancelProcessedPayment({payment: this.selected}).then(response => {
        this.$emit('close_payment_dialog')
      })
    },

    esewaPayment: function (total, tax = 0) {
      let selectedIds = this.selected.join("");
      let successUrl = process.env.VUE_APP_ESEWA_SUCCESS_URL;
      let errorUrl = process.env.VUE_APP_ESEWA_ERROR_URL;
      var path = process.env.VUE_APP_ESEWA_PAYMENT_URL;
      var params = {
        amt: (parseFloat(total) - parseFloat(tax)),
        psc: 0,
        pdc: 0,
        txAmt: tax,
        tAmt: total,
        pid: `${selectedIds}-${total}-${this.user.user_personal_id}`,
        scd: this.gateways.esewa_mid,
        su: successUrl,
        fu: errorUrl
      };

      var form = document.createElement("form");
      form.setAttribute("method", "POST");
      form.setAttribute("action", path);

      for (var key in params) {
        var hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", params[key]);
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      form.submit();
    },

    payWithKhalti(total, tax = 0) {
      let selectedIds = this.selected.join("");
      var config = {
        // replace the publicKey with yours
        "publicKey": this.gateways.khalti_pk,
        "productIdentity": `${selectedIds}-${total}-${this.user.user_personal_id}`,
        "productName": `Invoice-${total}-${this.user.user_personal_id}`,
        "productUrl": process.env.VUE_APP_KHALTI_PRODUCT_URL,
        "paymentPreference": [
          "KHALTI",
          "EBANKING",
          "MOBILE_BANKING",
          "CONNECT_IPS",
          "SCT",
        ],


        "eventHandler": {
          onSuccess(payload) {
            // hit merchant api for initiating verfication
            console.log(payload);
          },
          onError(error) {
            console.log(error);
          },
          onClose() {
            console.log('widget is closing');
          }
        }
      };
      var checkout = new KhaltiCheckout(config);
      var btn = document.getElementById("payment-button");
      // minimum transaction amount must be 10, i.e 1000 in paisa.
      checkout.show({amount: (parseInt(total) * 100)});
    },
    initiateConnectIPS(total) {
      let data = {
        total: total,
      }
      feeService.initiateConnectIPS(data).then(response => {
        let params = response.data
        var successPath = params.GATEWAYURL
        delete params.GATEWAYURL;
        var form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", successPath);
        for (var key in params) {
          var hiddenField = document.createElement("input");
          hiddenField.setAttribute("type", "hidden");
          hiddenField.setAttribute("name", key);
          hiddenField.setAttribute("value", params[key]);
          form.appendChild(hiddenField);
        }
        document.body.appendChild(form);
        form.submit();
      })
    },
  }
}
</script>

<style scoped>

</style>