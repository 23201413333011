<template>
  <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="px-3">
          <th>Date</th>
          <th>Title</th>
        </tr>
        </thead>
        <tbody v-if="upcoming_payments && upcoming_payments.length>0">
        <tr v-for="(upcoming, index) of upcoming_payments" :key="index">
          <td>{{upcoming.month}}</td>
          <td>
            <span v-for="(payment, i) of upcoming.payments" :key="i">{{`${payment.title?payment.title:'other'} - Rs.${payment.amount}`}} <br></span>
          </td>
        </tr>
        </tbody>
        <tbody v-else>
        <tr>
          <td colspan="2"> No items found</td>
        </tr>
        </tbody>
      </table>
  </div>

</template>


<script>
import InvoiceService from "@/core/services/invoice/InvoiceService";
const inovice = new InvoiceService();
export default {
  name: "upcoming-payments",
  data(){
    return{
      upcoming_payments:[]
    }
  },
  mounted() {
    this.getUpcomingPayments();
  },
  methods:{
    getUpcomingPayments() {
      inovice.getUpcomingPaymentsOfStudents().then(response => {
        this.upcoming_payments = response.data.payment;
      }).catch(error => {
        this.$snotify.error("Something went wrong");
      })
    },
  }
}
</script>

<style scoped>

</style>