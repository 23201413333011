import Apiservice from '@/core/services/api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class PaymentGatewaySettingService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/site-setting/payment-gateways`;
    }

    getAvailablePaymentGateways(){
        let url = `${this.#api}`;
        return apiService.get(url);
    }
}

