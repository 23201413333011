import Apiservice from '../api.service';
import {API_URL} from '../../../common/config.js'

const apiService = Apiservice;


export default class InvoiceService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;
    }

    getInvoice() {
        let url = `${this.#api}/invoice`
        return apiService.get(url)
    }
    show(id){
        let url = `${this.#api}/invoice/${id}`
        return apiService.get(url)
    }

    getPendingPayments() {
        let url = `${this.#api}/pending-payments`
        return apiService.get(url);
    }

    getUpcomingPaymentsOfStudents() {
        let url = `${this.#api}/upcoming-payments`
        return apiService.get(url);
    }
}