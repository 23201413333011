import Apiservice from '../../../api.service';
import {API_URL} from '../../../../../common/config.js'

const apiService = Apiservice;


export default class StudentFeeService {
    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student`;

    }

    paginate(userId) {
        let url = `${this.#api}/${userId}/student-fee`;
        return apiService.get(url);
    }

    update(userId, data) {
        let url = `${this.#api}/${userId}/student-fee`
        return apiService.put(url, data);

    }

    store(userId, data) {
        let url = `${this.#api}/${userId}/student-fee`
        return apiService.post(url, data);

    }

    show(userId) {
        let url = `${this.#api}/${userId}/student-fee`
        return apiService.get(url);
    }

    delete(userId, id) {
        let url = `${this.#api}/${userId}/student-fee/${id}`
        return apiService.delete(url);
    }

    getStudentPending(userId) {
        let url = `${this.#api}/${userId}/student-fee/stats/all`
        return apiService.get(url);
    }

    processPayment(data) {
        let url = `${this.#api}/student-fee/process/payment`
        return apiService.post(url, data);
    }

    cancelProcessedPayment(data) {
        let url = `${this.#api}/student-fee/cancel-processed/payment`
        return apiService.post(url, data);
    }

    validateEsewa(data) {
        let url = `${this.#api}/student-fee/validate/esewa`
        return apiService.post(url, data);
    }

    validateConnectIPS(data) {
        let url = `${this.#api}/student-fee/validate/connect-ips`
        return apiService.post(url, data);
    }

    initiateConnectIPS(data) {
        let url = `${this.#api}/student-fee/initiate/connect-ips`
        return apiService.post(url, data);
    }

}